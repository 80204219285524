<template>
	<div class="HSMask">
		<div class="HSDialog" style="min-height: 340px;min-width: 430px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">{{title}}</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogContent">
				<div class="dialogItem">
					<div class="dialogName">
						<span style="color: #FF3939;">*</span>
						<span>供应商名称:</span>
					</div>
					<el-input placeholder="请输入供应商名称" v-model="name" clearable style="width: 247px;">
					</el-input>
				</div>
				<div class="dialogItem">
					<div class="dialogName">联系人:</div>
					<el-input placeholder="请输入联系人" v-model="param1" clearable style="width: 247px;">
					</el-input>
				</div>
				<div class="dialogItem">
					<div class="dialogName">联系电话:</div>
					<el-input type="phone" placeholder="请输入联系电话" v-model="param2" clearable style="width: 247px;">
					</el-input>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			addType: {
				default: 0 //0新增  1编辑
			},
			nodeData: {  //编辑信息
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				title: '', //弹框名字
				param1:'',  //联系人
				param2:'', //联系电话
				name:'',  //名称
			}
		},
		mounted() {
			if (this.addType == 0) {
				this.title = '新增供应商'
			} else {
				this.title = '编辑供应商';
				this.name = this.nodeData.name;
				this.param1 = this.nodeData.param1;
				this.param2 = this.nodeData.param2;
			}
		},
		methods: {
			// 关闭
			close() {
				this.$emit("submit", {
					type: "close",
					data: ""
				})
			},
			// 提交
			submit() {
				if(this.name == ''){
					this.$message({
						message: '请添加供应商名称',
						type: 'error'
					});
					return
				}
				if(this.addType == 0){  //新增
					let params = {
						parentId:this.nodeData.id,
						companyInfo:this.nodeData.companyInfo,
						name:this.name,
						param1:this.param1,
						param2:this.param2
					}
					if(this.nodeData.itemList.length == 0){
						params.order = 1
					}else{
						params.order = this.nodeData.itemList[this.nodeData.itemList.length-1].order + 1
					}
					this.$http.post("/cms/System/AddSystemDiction", params).then(res => {
						if (res.code === 0) {
							this.$message({
								message: '新增成功',
								type: 'success'
							});
							this.$emit("submit",{type:'success',data:res.data})
						}
					})
				}else{  //编辑
					let params = {
						id:this.nodeData.id,
						companyInfo:this.nodeData.companyInfo,
						name:this.name,
						code:this.nodeData.code,
						remark:this.nodeData.remark,
						order:this.nodeData.order,
						value:this.nodeData.value,
						param1:this.param1,
						param2:this.param2,
						isDef:this.nodeData.isDef
					}
					this.$http.post("/cms/System/UpdateSystemDiction", params).then(res => {
						if (res.code === 0) {
							this.$message({
								message: '修改成功',
								type: 'success'
							});
							this.$emit("submit",{type:'success',data:res.data})
						}
					})
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.HSDialogContent {
		padding: 20px 32px 0 32px;
		.dialogItem{
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			height: 40px;
			font-size: 16px;
			.dialogName{
				width: 98px;
				text-align: right;
				margin-right: 4px;
			}
		}
	}
</style>
