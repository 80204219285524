<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="searchView">
			<el-input v-model="keyword" placeholder="请输入供应商名称" prefix-icon="iconfont el-icon-search"
				style="width: 200px;margin-right: 8px;" @keyup.enter.native="handleSearchClick"></el-input>
			<el-button type="primary" style="margin-right: 32px;" @click="handleSearchClick">查询</el-button>
			<div class="btnView">
				<el-button @click="handleResetClick">重置</el-button>
				<el-button v-if="$buttonAuthority('cangku-gongyingshangList-addgongyingshang')" type="primary" @click="dialogSupplier_state = true;addType = 0;nodeData =tableCopy">新建供应商</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="tableView">
			<el-table :data="tableData">
				<el-table-column prop="name" label="供应商名称">
				</el-table-column>
				<el-table-column label="联系人">
					<template slot-scope="scope">
						{{scope.row.param1 == ''?'--':scope.row.param1}}
					</template>
				</el-table-column>
				<el-table-column label="联系电话">
					<template slot-scope="scope">
						{{scope.row.param2 == ''?'--':scope.row.param2}}
					</template>
				</el-table-column>
				<el-table-column label="添加时间">
					<template slot-scope="scope">
						<span>{{scope.row.operationInfo.create.handleTime == null?'--':scope.row.operationInfo.create.handleTime}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button v-if="$buttonAuthority('cangku-gongyingshangList-editgongyingshang')" @click="handleEditClick(scope.row)" type="text" size="small">编辑</el-button>
						<el-button v-if="$buttonAuthority('cangku-gongyingshangList-seegongyingshang')" type="text" size="small" @click="handleDeleteClick(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div v-if="dialogSupplier_state">
			<dialogSupplier @submit="submit_dialogSupplier" :nodeData="nodeData" :addType="addType">
			</dialogSupplier>
		</div>
	</div>
</template>

<script>
	import dialogSupplier from "../../../components/dialogSupplier.vue"
	export default {
		components:{
			dialogSupplier
		},
		data() {
			return {
				tableData: [], //表格
				dialogSupplier_state:false,  //弹框状态
				keyword:'',
				addType:0,  //跳转状态
				tableCopy:{},  //拷贝数据
				nodeData:{},  //跳转数据
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//删除
			handleDeleteClick(row){
				this.$confirm('是否删除该供应商?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = {
						id:row.id,
						companyId:row.companyInfo.id
					}
					this.$http.get("/cms/System/DeleteSystemDiction",params).then(res => {
						if (res.code === 0) {
							this.$message({
								message: '已删除',
								type: 'success'
							});
							this.getTableData()
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
				
			},
			//重置
			handleResetClick(){
				this.keyword = '';
				this.tableData = this.tableCopy.itemList;
			},
			//查询
			handleSearchClick(){
				if(this.keyword == ''){
					this.tableData = this.tableCopy.itemList
				}else{
					this.tableCopy.itemList.map((item)=>{
						if(this.keyword == item.name){
							this.tableData = [item];
						}
					})
				}
				
			},
			//编辑
			handleEditClick(row){
				this.addType = 1;
				this.nodeData = row;
				this.dialogSupplier_state = true;
			},
			//弹框确认
			submit_dialogSupplier(){
				this.getTableData()
				this.dialogSupplier_state = false;
			},
			//表格
			getTableData(){
				let params = {
					code:'suppliertype'
				}
				this.$http.get("/cms/System/GetSystemDiction",params).then(res => {
					if (res.code === 0) {
						this.tableCopy = res.data;
						this.tableData = this.tableCopy.itemList;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
		display: flex;
		flex-direction: column;

		.searchView {
			height: 80px;
			border-radius: 20px;
			margin-bottom: 16px;
			background: #fff;
			display: flex;
			align-items: center;
			padding: 0 20px;

			.search {
				display: flex;
				align-items: center;
				margin-right: 32px;

				.searchTitle {
					font-size: 14px;
					color: $fontColor;
				}
			}

			.btnView {
				flex: 1;
				text-align: right;
			}
		}

		.tableView {
			border-radius: 20px;
			overflow-x: hidden;
			flex: 1;
			background: #fff;
			overflow-y: scroll;
		}
		.tableView::-webkit-scrollbar {
			display: none;
		}
	}
</style>
